<template>
  <ag-grid-vue
    style="width: 100%; height: 400px;"
    class="ag-theme-alpine"
    :gridOptions="gridOptions"
    @grid-ready="onGridReady"
    :columnDefs="columnDefs"
    :rowData="rowData"
    :rowSelection="rowSelection"
    :setQuickFilter="updateSearchQuery"
    suppressDragLeaveHidesColumns
    @selection-changed="onSelectionChanged"
    suppressMovableColumns
    checkboxSelection="true"
  >
  </ag-grid-vue>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import moment from "moment";
import { mapGetters } from "vuex";

import { toShowAccountingPrice } from "../../../util/accountingPrice";

export default {
  emits: ["selected-assets"],
  data() {
    return {
      columnDefs: null,
      rowSelection: "multiple",
      gridApi: "sizeColumnsToFit",
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
    };
  },
  props: {
    page: { type: Number },
    assetData: { default: () => [] },
    startMonth: {
      required: false,
      type: Number,
      default: 1,
    },
    searchText: {
      type: String,
    },
    otherColumn: [],
    perPage: { type: Number, default: 100 },
  },
  beforeMount() {},
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.loopHeaderFreeColumn();
  },
  computed: {
    ...mapGetters({
      isRoleLower: "isRoleLower",
      permissionStatus: "permissionStatus",
      canUseCustom2: "canUseCustom2",
      canUseCustom4: "canUseCustom4",
    }),

    rowData() {
      return this.assetData.map((asset, index) => {
        let dataTable = {
          index: (this.page - 1) * this.perPage + index + 1,
          id_fixed_asset: asset.assetId,
          name: asset.assetName || "-",
          description: asset.assetDescription,
          note_acc: asset.noteAccount || "-",
          code_acc: asset.accountCode || "-",
          name_acc: asset.accountName || "-",
          category: asset.assetCategory || "-",
          sub_category: asset.assetSubcategory || "-",
          model_name: asset.assetSubcategory2 || "-",
          location_building: asset.building || "-",
          location_floor: asset.floor || "-",
          department_code: asset.department_code || "-",
          location_department: asset.department || "-",
          location_zone: asset.zone || "-",
          note: asset.note ? asset.note : "-",
          id_qrcode: this.qrCode(asset),
          depreciation_status: this.dpStatusTranslate(asset.depreciationStatus),
          dp_start_date: moment(asset.startDate, "DD-MM-YYYY").isValid()
            ? moment(asset.startDate, "DD-MM-YYYY").format("DD/MM/YYYY")
            : "-",
          purchase_price: asset.purchasePrice
            ? toShowAccountingPrice(asset.purchasePrice)
            : "-",
          incoming_dp: asset.incomingAccDepreciation
            ? toShowAccountingPrice(asset.incomingAccDepreciation)
            : "-",
          january: toShowAccountingPrice(asset.jan),
          february: toShowAccountingPrice(asset.feb),
          march: toShowAccountingPrice(asset.mar),
          april: toShowAccountingPrice(asset.apr),
          may: toShowAccountingPrice(asset.may),
          june: toShowAccountingPrice(asset.jun),
          july: toShowAccountingPrice(asset.jul),
          august: toShowAccountingPrice(asset.aug),
          september: toShowAccountingPrice(asset.sep),
          october: toShowAccountingPrice(asset.oct),
          november: toShowAccountingPrice(asset.nov),
          december: toShowAccountingPrice(asset.dec),
          total_dp: toShowAccountingPrice(asset.accDepreciation),
          residual: toShowAccountingPrice(asset.residual),
          outgoing_dp: toShowAccountingPrice(asset.outcomingAccDepreciation),
          writeoff_acc: toShowAccountingPrice(asset.writeOffAcc),
          net_book: toShowAccountingPrice(asset.netBook),
          // other_1: asset.other_1,
          // other_2: asset.other_2,
          // other_3: asset.other_3,
          // other_4: asset.other_4,

          asset_sector: asset.sector || "-",
          asset_branch: asset.branch || "-",
          insurance_start: moment(asset.customStartTime).isValid()
            ? this.momentDay(asset.customStartTime)
            : "-",
          insurance_end: moment(asset.customEndTime).isValid()
            ? this.momentDay(asset.customEndTime)
            : "-",
          vendor: asset.vendor || "-",
        };

        return dataTable;
      });
    },
  },
  components: {
    AgGridVue,
  },
  watch: {
    searchText() {
      this.updateSearchQuery(this.searchText);
    },
    rowData() {
      this.loopHeaderFreeColumn();
    },
  },
  methods: {
    dpStatusTranslate(status) {
      switch (status) {
        case "active":
          return "Active";
        case "expire":
          return "Fully Depreciated";
        case "deactivate":
          return "Deactivate";
        case "write off":
          return "Written off / Disposed";
        default:
          return "-";
      }
    },
    qrCode(asset) {
      if (asset.qrCode === "-" || !asset.qrCode) {
        return this.$t("home.not_reg");
      } else {
        return asset.qrCode;
      }
    },
    loopHeaderFreeColumn() {
      let columnDefs = [
        {
          headerName: this.$t("count.order"),
          field: "index",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          maxWidth: 110,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("home.fixedas_num"),
          field: "id_fixed_asset",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.as_name"),
          field: "name",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("home.as_dt"),
          field: "description",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("home.acc_note"),
          field: "note_acc",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("home.acc_code"),
          field: "code_acc",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("home.acc_name"),
          field: "name_acc",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("home.cat"),
          field: "category",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("home.sub_cat"),
          field: "sub_category",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("model.model"),
          field: "model_name",
          sortable: true,
          minWidth: 200,
          hide: !this.canUseCustom4,
        },
        {
          headerName: this.$t("asset.location"),
          field: "asset_sector",
          sortable: true,
          suppressSizeToFit: true,
          hide: !this.canUseCustom2 || this.isRoleLower,
        },
        {
          headerName: this.$t("asset.branch"),
          field: "asset_branch",
          sortable: true,
          suppressSizeToFit: true,
          hide: !this.canUseCustom2 || this.isRoleLower,
        },
        {
          headerName: this.$t("home.build"),
          field: "location_building",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.fl"),
          field: "location_floor",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.department_code"),
          field: "department_code",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.department"),
          field: "location_department",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.zone"),
          field: "location_zone",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.note"),
          field: "note",
          sortable: true,
          minWidth: 150,
        },
        {
          headerName: "QR Code",
          field: "id_qrcode",
          sortable: true,
          suppressSizeToFit: true,
          cellStyle: (params) => {
            if (params.value === this.$t("home.not_reg")) {
              return { color: "#888888" };
            }
            return null;
          },
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.status"),
          field: "depreciation_status",
          sortable: true,
          minWidth: 150,
          cellRenderer: (params) => {
            return params.value;
          },
          cellStyle: (params) => {
            switch (params.value) {
              case "Active":
                return { color: "#00AB1B" };
              case "Expired":
                return { color: "#7B61FF" };
              case "Deactivate":
                return { color: "#E9A800" };
              case "Write off (Sold)":
                return { color: "#ED1616" };
              default:
                return null;
            }
          },
          cellClass: "text-center",
        },
      ];

      if (!this.isRoleLower) {
        const sortedMonthColumn = this.sortMonth();
        let fix_column = [
          {
            headerName: this.$t("asset.purchase_price"),
            field: "purchase_price",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("depreciation.dp_start_date"),
            field: "dp_start_date",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            cellClass: "text-center",
          },
          {
            headerName: this.$t("depreciation.incoming_dp"),
            field: "incoming_dp",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            cellClass: "text-end",
          },
          ...sortedMonthColumn,
          {
            headerName: this.$t("depreciation.total_dp"),
            field: "total_dp",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("depreciation.writeoff_total_dp"),
            field: "residual",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("depreciation.outgoing_dp"),
            field: "outgoing_dp",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("depreciation.writeoff_asset_price"),
            field: "writeoff_acc",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("depreciation.net_book"),
            field: "net_book",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("asset.insurance_start"),
            field: "insurance_start",
            sortable: true,
            suppressSizeToFit: true,
            hide: !this.canUseCustom2,
          },
          {
            headerName: this.$t("asset.insurance_end"),
            field: "insurance_end",
            sortable: true,
            suppressSizeToFit: true,
            hide: !this.canUseCustom2,
          },
          {
            headerName: this.$t("asset.vendor"),
            field: "vendor",
            sortable: true,
            suppressSizeToFit: true,
            hide: !this.canUseCustom2,
          },
        ];
        columnDefs = columnDefs.concat(fix_column);
      }
      this.columnDefs = columnDefs;
    },
    sortMonth() {
      const startMonth = this.startMonth;
      const sortedMonth = [];
      let monthColumn = [
        {
          headerName: this.$t("adddate.jan"),
          field: "january",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.feb"),
          field: "february",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.mar"),
          field: "march",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.apr"),
          field: "april",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.may"),
          field: "may",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.june"),
          field: "june",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.july"),
          field: "july",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.aug"),
          field: "august",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.sep"),
          field: "september",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.oct"),
          field: "october",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.nov"),
          field: "november",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.dec"),
          field: "december",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
      ];
      if (startMonth > 1) {
        for (let i = startMonth - 1; i <= monthColumn.length - 1; i++) {
          sortedMonth.push(monthColumn[i]);
        }
        for (let i = 0; i < startMonth - 1; i++) {
          sortedMonth.push(monthColumn[i]);
        }
        return sortedMonth;
      } else {
        return monthColumn;
      }
    },
    onGridReady() {
      this.gridApi.sizeColumnsToFit();
    },
    momentDay(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) =>
        this.assetData.find(
          (asset) => asset.id_fixed_asset === node.data.id_fixed_asset
        )
      );
      return selectedData;
    },

    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => {
        return node.data;
      });
      this.$emit("selected-assets", selectedData);
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
