<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <!-- Export Modal -->
    <b-modal
      class="p-2"
      id="export-modal"
      hide-header-close
      hide-header
      hide-footer
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('export-modal')"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center pb-3 btn-auto">{{ $t("asset.choose_ex") }}</p>
      <b-row align-h="center">
        <!-- <button class="px-2 email-me" @click="onExport(true)">
          {{ $t("btn.send_mail") }}
        </button> -->

        <button class="px-2 download-file" @click="onExport(false)">
          {{ $t("btn.dl_file") }}
        </button>
      </b-row>
    </b-modal>

    <div class="manage-accounting my-3" style="padding: 0.4em">
      <div class="main">
        <p class="company-name">{{ companyName }}</p>
        <div
          class="menu d-flex flex-wrap align-items-center justify-content-between my-3"
        >
          <div class="d-flex align-items-center flex-wrap">
            <button
              v-if="!isNativeApp"
              class="btn-outline primary-btn me-3 d-flex my-1"
              @click="$bvModal.show('export-modal')"
            >
              <b-icon-upload font-scale="1.5" style="margin-right: 5px" />
              <p>{{ $t("depreciation.export_file") }}</p>
            </button>
            <select
              class="form-control w-auto me-3 my-1"
              v-model="tableType"
              @change="loadAssetData"
            >
              <option value="piece">{{
                $t("depreciation.info_per_piece")
              }}</option>
              <option value="group">{{
                $t("depreciation.info_per_group")
              }}</option>
            </select>
            <select
              class="form-control w-auto me-3 my-1"
              v-model="infoYear"
              @change="loadAssetData"
            >
              <option
                v-for="year in yearList"
                :key="year.value"
                :value="year.value"
                >{{ year.label }}</option
              >
            </select>
          </div>
          <div
            class="search-box d-flex"
            style="border-radius: 10px;"
            v-if="showingTable === 'piece'"
          >
            <img src="@/assets/search-white.svg" width="20px" />
            <input
              type="text"
              v-debounce:700ms="searchByText"
              :placeholder="$t('home.search')"
              v-model="searchText"
            />
          </div>
        </div>
        <ReportIndividualTable
          v-if="showingTable === 'piece'"
          @selected-assets="setSelectedAsset"
          :assetData="assetList"
          :otherColumn="otherColumn"
          :page="currentPage"
          :startMonth="accountMonth"
          :perPage="perPage"
        />
        <ReportGroupTable
          v-else
          @selected-assets="setSelectedAsset"
          :assetData="assetList"
          :otherColumn="otherColumn"
          :page="currentPage"
          :startMonth="accountMonth"
          :perPage="perPage"
        />
        <div class="d-flex justify-content-center mt-3">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
            @input="loadAssetData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import axios from "axios";
import baseUrl from "../../util/backend";
import { authHeader } from "../../store/actions";
import moment from "moment";
import ReportIndividualTable from "../../components/Table/Depreciation/ReportIndividualTable.vue";
import ReportGroupTable from "../../components/Table/Depreciation/ReportGroupTable.vue";
import { mapGetters } from "vuex";
export default {
  name: "DepreciationReport",
  components: {
    ReportIndividualTable,
    ReportGroupTable,
    Loading,
  },
  data() {
    return {
      isLoading: true,
      company_id: null,
      infoYear: new Date().getFullYear(),
      selectedAsset: [],
      tableType: "piece",
      showingTable: "piece",
      currentPage: 1,
      totalRows: 1,
      perPage: 50,
      assetList: [],
      otherColumn: [],
      searchText: "",
      filterOptions: {},
      accountingNoteFilter: undefined,
      accountMonth: 1,
      accountYear: null,
      yearList: [],
      accountNoteList: [],
      groupUpdateAt: null,
    };
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "";
    },
  },
  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
      getAllAssetOtherColumn: "getAllAssetOtherColumn",
      companyName: "companyName",
    }),
    selectedAssetLength() {
      return this.selectedAsset.length > 0;
    },
  },
  methods: {
    moment,
    async onExport(isMail) {
      this.isLoading = true;
      try {
        const filename =
          this.tableType === "piece"
            ? this.$t("depreciation.depreciation_report_excel_single")
            : this.$t("depreciation.depreciation_report_excel_group");
        const type =
          this.tableType === "piece" ? "dpPerMonthPiece" : "dpPerMonthGroup";
        const res = await this.$store.dispatch("ExportDpReport", {
          is_email: isMail,
          company_id: this.company_id,
          filterOptions: this.filterOptions,
          filename: `${filename}_${this.companyName}_${moment().format(
            "DD-MM-YYYY_HH-mm"
          )}.xlsx`,
          type: type,
          selected_assets: [],
          year: this.infoYear,
        });
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    setSelectedAsset(value) {
      this.selectedAsset = value;
    },
    searchByText() {
      this.filterOptions.text = this.searchText;
      this.currentPage = 1;
      this.loadAssetData();
    },
    setYearList() {
      const accountingYear = this.accountYear;
      const list = [];
      const accountingMonth = this.accountMonth - 1;
      const currentYear = new Date().getFullYear();
      if (accountingMonth > 0) {
        let index = 0;
        for (let i = accountingYear; i <= +currentYear; i++) {
          if (
            moment().isSameOrAfter(
              moment(
                [accountingYear + index, accountingMonth + 1],
                "YYYY-M"
              ).startOf("M")
            )
          ) {
            list.push({
              label: `${this.$t(
                `adddate.month_short[${accountingMonth}]`
              )} ${accountingYear + index} - ${this.$t(
                `adddate.month_short[${accountingMonth - 1}]`
              )} ${accountingYear + 1 + index}`,
              value: accountingYear + index,
            });
          }
          index += 1;
        }
      } else {
        let index = 0;
        for (let i = accountingYear; i <= +currentYear; i++) {
          list.push({
            label: `${this.$t(
              `adddate.month_short[${accountingMonth}]`
            )} ${accountingYear + index} - ${this.$t(
              `adddate.month_short[11]`
            )} ${accountingYear + index}`,
            value: accountingYear + index,
          });
          index += 1;
        }
      }
      this.yearList = list;
      this.infoYear = list[list.length - 1].value;
    },
    async getCompanyData() {
      this.company_id = await this.$store.dispatch("getCompanyIdCookie");
      try {
        const res = await axios.get(
          `${baseUrl()}company/getCompany/${this.company_id}`,
          authHeader()
        );
        this.accountMonth = moment(res.data.data.accounting_period).isValid()
          ? +moment(res.data.data.accounting_period).format("M")
          : 1;
        this.accountYear = moment(res.data.data.accounting_period).isValid()
          ? +moment(res.data.data.accounting_period).format("YYYY")
          : new Date().getFullYear();
        this.setYearList();
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
    },
    async getAccountingNote() {
      try {
        const res = await axios.get(
          `${baseUrl()}asset/getAccountNoteOption/${this.company_id}`,
          authHeader()
        );
        this.accountNoteList = res.data.data;
      } catch (err) {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          console.log(err.message);
        }
      }
    },
    async loadAssetData() {
      this.isLoading = true;
      try {
        if (this.tableType === "piece") {
          const res = await axios.get(
            `${baseUrl()}asset/depreciationPerMonth/${this.company_id}`,
            {
              params: {
                page: this.currentPage,
                limit: this.perPage,
                year: this.infoYear,
                text: this.searchText || undefined,
              },
              ...authHeader(),
            }
          );
          const resData = res.data;
          this.assetList = resData.data.rows;
          this.totalRows = resData.data.count;
          this.otherColumn = resData.other_column;
          this.showingTable = this.tableType;
        }
        if (this.tableType === "group") {
          this.filterOptions.text = "";
          this.searchText = "";

          const res = await axios.get(
            `${baseUrl()}asset/depreciationPerMonthCate/${this.company_id}`,
            {
              params: {
                page: this.currentPage,
                limit: this.perPage,
                year: this.infoYear,
                note_accounting_id: this.accountingNoteFilter,
              },
              ...authHeader(),
            }
          );
          const resData = res.data;
          this.assetList = resData.data.rows;
          this.totalRows = resData.data.count;
          this.otherColumn = resData.other_column;
          this.showingTable = this.tableType;
          this.groupUpdateAt = resData.last_update || new Date();
        }
      } catch (err) {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          console.log(err.message);
        }
      }
      this.isLoading = false;
    },
    async initData() {
      await this.getCompanyData();
      this.getAccountingNote();
      this.loadAssetData();
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
input,
select {
  height: 45px;
}

.manage-accounting {
  text-align: left;
}

.company-name {
  font-size: 18px;
}

.btn-outline {
  p {
    margin: 0;
  }
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.primary-btn {
    color: #007afe;
    border-color: #007afe;
  }
  &.danger-btn {
    color: #f74949;
    border-color: #f74949;
  }
}

.form-control {
  min-width: 200px;
  width: 200px;
}

.search-box {
  input {
    width: 200px;
    background-color: transparent !important;
    border-radius: 10px;
  }
  img {
    margin: 0 10px;
  }
  background-color: #e0e0e0 !important;
}

.input-arrow {
  user-select: none;
  cursor: pointer;
  min-width: 200px;
  width: 200px;
  height: 45px;
  background-color: #f4f4f4;
  padding: 0.375rem 0.75rem;
  color: #212529;
  border-radius: 0.25rem;
  background-image: url("../../assets/down-arrow-black.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 97% 50% !important;
  padding-right: 40px !important;
}

@media only screen and (min-width: 1440px) {
  .main {
    overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}

.dropdown-style {
  background-color: #e0e0e0;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
  width: 200px;
  padding: 0.4rem;
}
.dropdown-style ::v-deep .vs__search {
  background-color: #e0e0e0 !important;
}
.dropdown-style ::v-deep .vs__dropdown-toggle {
  border: 1px solid transparent;
}
</style>
